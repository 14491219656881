// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bC_kC";
export var caseStudyBackground__lineColor = "bC_kz";
export var caseStudyHead = "bC_kt";
export var caseStudyHead__imageWrapper = "bC_kv";
export var caseStudyProjectsSection = "bC_kD";
export var caseStudyQuote__bgRing = "bC_kx";
export var caseStudyVideo__ring = "bC_kN";
export var caseStudy__bgDark = "bC_ks";
export var caseStudy__bgDarkReverse = "bC_ls";
export var caseStudy__bgLight = "bC_kr";